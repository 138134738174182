

































































































































import { Component, Vue } from "vue-property-decorator";
import { apiUrl, appName, appVer } from "@/env";
import {
  dispatchGetGeneralFuse,
  dispatchGetUnreadCount,
  dispatchListCountries,
  dispatchListLanguages,
  dispatchListSearchObjects,
  dispatchListSystemNotifications,
  dispatchListUserNotifications,
  dispatchSetNotificationSidebarVisible,
} from "@/store/main/actions";
import { readCountries, readSearchObjects } from "@/store/main/getters";

import { AppNotification } from "@/interfaces";
// import Pusher, { Channel } from "pusher-js";
import { api } from "@/api";
import { dispatchGetMe } from "@/store/auth/actions";
import { nativeNotification } from "@/utils";
import { readMe } from "@/store/auth/getters";
import store from "@/store";

// Pusher.Runtime.createXHR = function () {
//   const xhr = new XMLHttpRequest();
//   xhr.withCredentials = true;
//   return xhr;
// };

@Component
export default class Main extends Vue {
  public appName = appName;
  public sidebarKey = 0;
  public appVer = appVer;

  public tourBackground = false;
  public onboardWelcomeOpen = false;

  get user() {
    return readMe(this.$store);
  }

  onStartCallback() {
    this.tourBackground = true;
  }

  async onSkipCallback() {
    this.tourBackground = false;
    await api.updateOnboardProgress("myTour");
  }

  myCallbacks = {
    onStart: this.onStartCallback,
    onSkip: this.onSkipCallback,
  };

  myCustomPreviousStep(currentStep: number) {
    if (currentStep == 4 && !this.$route.path.includes("/manage")) {
      this.$router.push("/manage#tour-back");
    } else {
      try {
        this.$tours["myTour"].previousStep();
      } catch (e) {
        console.info(e);
      }
    }
  }

  myCustomNextStep(currentStep: number) {
    if (currentStep == 0 && !this.$route.path.includes("/manage")) {
      this.$router.push("/manage#tour-next");
    } else if (currentStep == 4 && !this.$route.path.includes("/mastersheet")) {
      this.$router.push("/mastersheet/youtube#tour-next");
    } else {
      try {
        this.$tours["myTour"].nextStep();
      } catch (e) {
        console.info(e);
      }
    }
  }

  public steps = [
    {
      target: "#v-step-0",
      header: {
        title: "Menu Side Bar",
      },
      content: `Navigate your way to your desired workspace.<br />
You can now navigate and insert information based on the platform/type of products.`,
      params: {
        enableScrolling: false,
        placement: "right",
      },
    },
    {
      target: "#v-step-1",
      header: {
        title: "Export",
      },
      content: `You can now export data based on the page that you’re on!<br/>
Exported data will follow the filters that are currently applied on the page.`,
    },
    {
      target: "#v-step-2",
      header: {
        title: "Add New Information",
      },
      content: `Add your new creator/podcast/manager’s information here.`,
    },
    {
      target: "#v-step-3",
      header: {
        title: "Search Bar",
      },
      content: `Looking for something?<br/> This convenient search bar will help you find relavant information with just one click.`,
    },
    {
      target: "#notification-bell",
      header: {
        title: "Notifications",
      },
      content: `There are two type of news you will get from your notification bar.<br/><br/> Don't miss out on the important updates!`,
    },
    {
      target: ".mastersheet-tabs",
      header: {
        title: "The Mastersheet",
      },
      content: `This is a powerful table with spreadsheet-like functionalities such as selection, filtering, sorting, and more that allow you to view, edit, and export data on our accounts`,
      params: {
        placement: "bottom",
      },
    },
    {
      target:
        "#main > div > div.main-container > div:nth-child(2) > div.mastersheet-table-wrapper > div > div > div.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed > div.ag-side-bar.ag-unselectable.ag-side-bar-right",
      header: {
        title: "Column Customization",
      },
      content: `Is the table too long for you to look at and scroll? Remove unnecessary columns and clean up your view by selecting the boxes here.`,
      before: () => {
        (
          document.getElementsByClassName(
            "ag-side-button-button"
          )[0] as HTMLElement
        ).click();
      },
      params: {
        placement: "left",
      },
    },
    {
      target:
        "#main > div > div.main-container > div:nth-child(2) > div.mastersheet-table-wrapper > div > div > div.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed > div.ag-side-bar.ag-unselectable.ag-side-bar-right",
      header: {
        title: "Bonus!",
      },
      content: `Your preferences for hiding columns are saved for each view (by clicking save), so you can use different settings without affecting other layouts.`,
      params: {
        placement: "left",
      },
    },
    {
      target: "#v-step-0",
      header: {
        title: "Analytics",
      },
      content: `View our visual data analytics. You can compare between countries, platforms, contacts and more.`,
      params: {
        placement: "bottom",
      },
    },
  ];

  get searchObjects() {
    return readSearchObjects(this.$store);
  }

  get countries() {
    return readCountries(this.$store);
  }

  public setContentDisplay(item: AppNotification) {
    if (item.system) {
      return item.data.message;
    }
    switch (item.data.type) {
      case "mention":
        return `${item.data.author_name} mentioned you in a comment on the ${item.data.root_type} ${item.data.root_name}`;
      case "comment":
        return `${item.data.author_name} commented on your ${item.data.root_type} ${item.data.root_name}`;
    }
  }
  // public userNotificationBind(channel: Channel) {
  //   const t = this;
  //   const userId = this.user.id;
  //   channel.bind(`notification-change-${userId}`, async (data) => {
  //     await dispatchGetUnreadCount(this.$store);
  //     await dispatchListSystemNotifications(this.$store, { read: null });
  //     await dispatchListUserNotifications(this.$store, { read: null });
  //     // TODO: endpoints for permission
  //     // TODO: fix BambooHR pics
  //     // FIXME: still issues with reactivity. Maybe move to Notifications.vue?
  //     // FIXME: desktop notifications not working? Needs verification
  //     if (data.new_notification) {
  //       const content = this.setContentDisplay(data.notification);
  //       t["$toast"].add({
  //         severity: "info",
  //         group: "tr",
  //         summary: "New notification",
  //         detail: content,
  //         life: 2000,
  //       });
  //       nativeNotification(
  //         "OMNI new notification",
  //         content,
  //         data.notification.id.toString(),
  //         async () => {
  //           await dispatchSetNotificationSidebarVisible(this.$store, true);
  //         }
  //       );
  //     }
  //   });
  //   return channel;
  // }

  // public generalNotificationBind(channel: Channel) {
  //   channel.bind(`notification-change-0`, async (data) => {
  //     await dispatchGetUnreadCount(this.$store);
  //     await dispatchListSystemNotifications(this.$store, { read: null });
  //     if (data.new_notification) {
  //       const content = this.setContentDisplay(data.notification);
  //       this["$toast"].add({
  //         severity: "info",
  //         group: "tr",
  //         summary: "New notification",
  //         detail: content,
  //         life: 2000,
  //       });
  //       nativeNotification(
  //         "OMNI new notification",
  //         content,
  //         data.notification.id.toString() + "asdasdasdas",
  //         async () => {
  //           await dispatchSetNotificationSidebarVisible(this.$store, true);
  //         }
  //       );
  //     }
  //   });
  //   return channel;
  // }

  // public onlineUsersBind(channel: Channel) {
  //   // TODO: online user detection
  //   channel.bind(`pusher_internal:member_added`, async (member) => {
  //     console.log(member);
  //   });
  //   return channel;
  // }

  // public subscribe() {
  //   const userId = this.user.id;
  //   if (userId) {
  //     let pusher = new Pusher(pusherKey, {
  //       authEndpoint: `${apiUrl}/api/pusher/presence`,
  //       auth: {
  //         params: {
  //           user_id: userId,
  //         },
  //       },
  //       cluster: pusherCluster,
  //     });
  //     let channel = pusher.subscribe("presence-omni");
  //     channel = this.userNotificationBind(channel);
  //     channel = this.generalNotificationBind(channel);
  //     channel = this.onlineUsersBind(channel);
  //   }
  // }

  public async created() {
    // this.subscribe();
    if (!(this.user.tutorial_progress || []).includes("myTour")) {
      this.onboardWelcomeOpen = true;
    }
  }

  public async mounted() {
    const s = this.$store;
    const userId = this.user.id;
    await dispatchGetUnreadCount(this.$store);
    await dispatchListUserNotifications(this.$store, { read: null });
    await dispatchListSystemNotifications(this.$store, { read: null });
    window["analytics"]["identify"](this.user.id, {
      email: this.user.email,
      scopes: this.user.scopes,
    });
    if (!this.countries.length) {
      await dispatchListCountries(s);
    }
    await dispatchListLanguages(s);
    await dispatchListSearchObjects(s);
    await dispatchGetGeneralFuse(s, this.searchObjects);
  }
}
